/* Material UI */
/* Dialog reset */

.MuiDialogTitle-root {
  background: #f0ad4e;
  color: #fff;
}

.MuiDialogContentText-root {
  color: #313335 !important;
  padding-top: 15px;
}

.MuiDialog-paperWidthSm {
  min-width: 500px;
}
/* drawer reset */

.MuiListItemIcon-root {
  min-width: 40px !important;
}

.MuiListItemIcon-root {
  color: #4656b8 !important;
}

/* Avatar reset */

.MuiAvatar-colorDefault {
  background: #4656b8 !important;
}

/* Button color reset */

.MuiButton-containedPrimary {
  background: #4656b8 !important;
}

.Mui-Button-outlinedPrimary {
  border: 1px solid #4656b8 !important;
  color: #4656b8 !important;
}
/* Material App bar reset */

.MuiAppBar-colorPrimary {
  background: #4656b8 !important;
}

/* Material Radio reset */

.MuiRadio-colorPrimary.Mui-checked {
  color: #4656b8 !important;
}
// material font reset

.MuiButton-root,
.MuiTextField-root,
.MuiInput-root,
.MuiFormControl-root,
.MuiFormLabel-root,
.MuiFormHelperText-root,
.MuiTypography-root {
  font-family: 'Montserrat' !important;
}

/* alert reset 
{ background: '#F0F2FF', color: '#4656B8', fontWeight: 700 }
*/
.cyberSecurity__alert.MuiAlert-standardInfo,
.cyberSecurity__alert.MuiAlert-message,
.cyberSecurity__alert.MuiAlert-icon {
  background: #f0f2ff !important;
  color: #4656b8 !important;
  font-weight: bold !important;
}

.infoAlert.MuiAlert-standardInfo,
.infoAlert.MuiAlert-message,
.infoAlert.MuiAlert-icon {
  background: #f0f2ff !important;
  color: #4656b8 !important;
  font-weight: bold !important;
  // border-radius: 9999px;
  margin-bottom: 15px;
}

.MuiTableCell-root.comment {
  color: #f44336;
  font-weight: bold;
}
// }
.MuiListItem-container {
  width: 100%;
}

// Dialog Title reset

.MuiDialogTitle-root {
  .MuiTypography-h6 {
    width: 90%;
  }
}
