.homeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url(../../img/top-left.png) left top no-repeat, url(../../img/logo-bg.png) 99.8% 70% no-repeat;

  @media (max-width: 1200px) {
    //flex-direction: column;
    flex-wrap: wrap;
    order: -1;
  }

  &__rightSide {
    text-align: right;

    .avatarContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      //width: 150px;

      span {
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        color: #4656b8;
        cursor: pointer;
        // margin-right: 20px;
      }
    }
  }
}

.time {
  color: #4656b8;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}

.homeFooter {
  background: url(../../img/footer-bg.png) center right no-repeat;
  height: 105px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 45px 30px;
  .credits {
    color: #aeabf2;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
  }
  .egov {
    display: flex;
    text-decoration: none;
    .logo {
      height: 50px;
    }
  }
}
