* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
}

.w100 {
  width: 100%;
}

.w30 {
  width: 30%;
  @media (max-width: 1400px) {
    width: 50%;
  }

  @media (max-width: 992px) {
    width: 100%;
    padding: 0 25px;
  }
}

.w50 {
  width: 50%;
  @media (max-width: 992px) {
    width: 100%;
  }
}

.w60 {
  width: 60%;
  @media (max-width: 992px) {
    width: 100%;
  }
}

.w70 {
  width: 70%;
  @media (max-width: 800px) {
    width: 100%;
  }
}

.w80 {
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.m {
  margin: 10px !important;
}

.mAuto {
  margin: auto;
}

.m0Auto {
  margin: 0 auto !important;
  display: block !important;
}

.mt {
  margin-top: 15px !important;
}

.mt2 {
  margin-top: 20px !important;
}

.mt3 {
  margin-top: 30px !important;
}

.mt4 {
  margin-top: 40px !important;
}

.mt10 {
  margin-top: 100px;
}

.mb {
  margin-bottom: 15px !important;
}

.mb2 {
  margin-bottom: 20px !important;
}

.mr {
  margin-right: 15px !important;
}

.mr2 {
  margin-right: 20px !important;
}

.mr-small {
  margin-right: 5px !important;
}

.mr3 {
  margin-right: 30px !important;
}

.mlAuto {
  margin-left: auto !important;
}
.ml {
  margin-left: 10px !important;
}

.ml2 {
  margin-left: 20px !important;
}

.ml3 {
  margin-left: 30px !important;
}

.mb3 {
  margin-bottom: 30px !important;
}

.mb4 {
  margin-bottom: 40px !important;
}

.p {
  padding-left: 15px;
}

.pt {
  padding-top: 10px;
}

.pt2 {
  padding-top: 20px;
}

.pt3 {
  padding-top: 30px;
}

.pb2 {
  padding-bottom: 20px;
}

.p {
  padding: 10px;
}

.p2 {
  padding: 20px;
}

.p3 {
  padding: 30px;
}

.pr3 {
  padding-right: 30px;
}

.pl3 {
  padding-left: 30px;
}

.container30 {
  width: 300px;
}

.dFJustifyFxEnd {
  justify-content: flex-end;
}

.dFlex {
  display: flex;
  flex-wrap: wrap;
}

.dFJustifyFxCenter {
  display: flex;
  justify-content: center;
}

.flexDirectionCol {
  flex-direction: column;
}

.dFJustifyFxSpaceBtwn {
  display: flex;
  justify-content: space-between;
}

.alignItemsCenter {
  align-items: center;
}

.dBlock {
  display: block !important;
}

.wrap {
  flex-wrap: wrap;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alignItemCenter {
  display: flex;
  align-items: center;
}

.text-white {
  color: #fff;
}

.nonUnderline {
  text-decoration: none;
}

.btn {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  text-decoration: none;
  @media (max-width: 992px) {
    width: 95%;
  }
  &__fileUpload {
    display: flex;
    width: 100%;

    &.end {
      justify-content: flex-end;
    }
    input[type='file'] {
      position: absolute;
      z-index: -1;
      top: 10px;
      left: 8px;
      font-size: 17px;
      color: #b8b8b8;
    }
    .btn-wrap {
      position: relative;
      label {
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 500;
        color: #4656b9;
        border: 1px solid #4656b9;
        text-align: center;
      }
      label.error {
        border: 1px solid #f44336;
        color: #f44336;
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      cursor: pointer;
      border-radius: 5px;
      // background-color: #3f51b5;
      color: #3f51b5;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      transition: all ease-out 0.3s;
      &:hover {
        background-color: #4656b9;
        color: #fff;
      }
    }
  }
}

.mainFont {
  font-family: 'Montserrat', sans-serif;
}

.form {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    padding-left: 25px;
  }

  &__textField {
    width: 95%;
  }
}

.containerTableComponent {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 40px;
}

.mainTitle {
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  background: #4656b8;
  padding: 20px;
  max-width: 50%;

  @media (max-width: 992px) {
    max-width: 100%;
    order: 1;
    margin-bottom: 20px;
    margin-top: 10px;
    padding: 20px;
    font-size: 14px;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  &.marginsTitle {
    margin: 35px 0 40px 0;
  }
}

.title {
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  color: #4656b8;
  text-transform: uppercase;
  background: #ffffff;
  border: 2px solid #4656b8;
  border-radius: 0px 20px;
  padding: 10px 20px;
  margin: 0px 40px;

  @media (max-width: 1400px) {
    margin: 0 20px;
    padding: 10px;
  }
}

.titleBorder {
  border: 1px dashed #4656b8;
  width: 110px;
  height: 0;

  @media (max-width: 1200px) {
    width: 70px;
  }
}

.nativeSelect {
  background: url(../../img/triangle-select-blue.png) no-repeat 90% 50% #fff;
  width: 108px;
  border: 1px solid #4656b8;
  border-radius: 25px;
  color: #4656b8;
  padding: 5px;
  appearance: none;
  &:focus {
    outline: none;
  }
  option {
    color: #4656b8;
  }
}

.nativeLabel {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  line-height: 1;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.54);

  &.error {
    color: #f44336;
  }
}

.player {
  height: 70%;
  width: 85%;
  margin: 0 auto;

  @media (max-width: 1100px) {
    width: 100%;
    height: 90%;
  }
}

.textarea {
  resize: none;
  outline: none;
  border: none;
  border-bottom: 1px solid #949494;
  border-bottom: 1px solid #949494;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1.5px;
  &:hover {
    border-bottom: 2px solid #010101;
  }

  &:focus {
    border-bottom: 2px solid #4656b8;
  }

  &.error {
    border-bottom: 2px solid #f44336;
  }
}

.errorStatus,
.errorMessage {
  color: #4656b8;
  font-family: 'Montserrat';
  text-transform: uppercase;
  display: flex;
  justify-content: center;
}

.errorStatus {
  font-size: 101px;
  font-weight: 500;
}

.errorMessage {
  font-size: 31px;
  font-weight: 700;
}

.percent {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 1.4em;
}

.notFound {
  max-width: 100%;
  margin: 50px auto !important;
  display: block;
}

.fileItem {
  color: #4656b8;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 1em;
}

.fileContainerList {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.listItemUI-enter-active {
  animation: element-enter 1000ms ease-in alternate-reverse;
}

.listItemUI-exit-active {
  animation: element-exit 1000ms ease-out forwards;
}

.error {
  color: #f44336 !important;
}

.link {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

@keyframes element-enter {
  0% {
    transform: translateX(0);
  }

  30% {
    opacity: 0.7;
    transform: translateX(15px);
  }

  100% {
    opacity: 0;
    transform: translateX(-500px);
  }
}

@keyframes element-exit {
  0% {
    transform: translateX(0);
  }

  30% {
    opacity: 0.7;
    transform: translateX(-15px);
  }

  100% {
    opacity: 0;
    transform: translateX(500px);
  }
}

.m_link,
.r_link {
  color: #3f51b5;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 35px 0;

  &-thumb {
    width: 150px;
    margin-bottom: 25px;
  }

  &-title {
    color: #999;
  }
}

.evaluationContainer {
  display: flex;
  justify-content: center;
  width: 250px;
  margin: 0 auto;
  padding: 15px;
  background-color: #f0f2ff;
  border-radius: 50px;
  color: #3f51b5;
}

.date {
  background-color: #f0f2ff;
  border-radius: 50px;
  border: 1px solid #e1e4fc;
  padding: 15px;
  width: 500px;
  text-align: center;
  font-weight: 600;
  margin: 0 auto;
  color: #3f51b5;
}

.doughnut-chart {
  max-width: 600px;
  margin: auto;
}

.bar-chart {
  max-width: 1000px;
  margin: auto;
}

.tableScroll {
  position: relative;
  max-height: 90vh;
  // commented code for hide scrollbars
  // scrollbar-width: none;

  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

.positionRel {
  position: relative;
}

.stickyHead {
  position: sticky;
  z-index: 10;
  color: #707070;
}

.white {
  background-color: #fff !important;
  z-index: 9;
}

.sidebar {
  position: sticky;
  background-color: inherit;
  left: 0;
  z-index: 9;
  border-bottom: 1px dashed #e4e4e4;
  border-right: 1px dashed #e4e4e4;
}

.sidebar:nth-child(2) {
  left: 180px;
}

.sidebar:nth-child(3) {
  left: 238px;
}

.scoreSidebar {
  position: sticky;
  background-color: #fff;
  left: 238px;
  z-index: 9;
  border-bottom: 1px dashed #e4e4e4;
  border-right: 1px dashed #e4e4e4;
}
