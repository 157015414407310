@import 'base/common';
@import 'base/libsReset';
@import 'base/layout';
@import 'components/signIn';
@import 'components/drawer';
@import 'components/orgForm';
@import 'components/assessment';
@import 'components/mark';
@import 'components/addLinks';
@import 'components/tableInfoSec';
@import 'components/infoSystem';
@import 'components/electronGovernment';
@import 'components/tableData';
@import 'components/hackaton';
@import 'components/outsourcingAgreement';
@import 'components/spinner';
@import 'components/listActs';
@import 'components/legislativeBasis';
@import 'components/monitoring';
@import 'components/regions';
@import '~video-react/styles/scss/video-react';

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular/Montserrat-Regular.eot');
  src: url('../fonts/Montserrat-Regular/Montserrat-Regular.svg') format('svg'),
    url('../fonts/Montserrat-Regular/Montserrat-Regular.ttf') format('truetype'),
    url('../fonts/Montserrat-Regular/Montserrat-Regular.woff') format('woff'),
    url('../fonts/Montserrat-Regular/Montserrat-Regular.woff2') format('woff2');
  font-weight: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Medium/Montserrat-Medium.eot');
  src: url('../fonts/Montserrat-Medium/Montserrat-Medium.svg') format('svg'),
    url('../fonts/Montserrat-Medium/Montserrat-Medium.ttf') format('truetype'),
    url('../fonts/Montserrat-Medium/Montserrat-Medium.woff') format('woff'), url('../fonts/Montserrat-Medium/Montserrat-Medium.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold/Montserrat-Bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Montserrat-Bold/Montserrat-Bold.svg') format('svg'), url('../fonts/Montserrat-Bold/Montserrat-Bold.ttf') format('truetype'),
    url('../fonts/Montserrat-Bold/Montserrat-Bold.woff') format('woff'), url('../fonts/Montserrat-Bold/Montserrat-Bold.woff2') format('woff2');
  font-weight: 700;
}

body {
  font-family: 'Montserrat', sans-serif;
}
