.statusProjects {
  border: 2px solid #0000ff80;
  border-radius: 20px;
  width: 150px;

  .title {
    margin: 10px 0;
    padding: 0;
    font-size: 10px;
    background-color: transparent;
    border: none;
  }

  .value {
    text-align: center;
    color: #666;
    margin: 0;
    padding: 0;
    font-size: 30px;
    font-weight: 600;
  }
}

.container-statuses {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 160px;

  .status_link {
    display: flex;
    flex-direction: row;
    color: #000;
    min-width: 30px;
    min-height: 30px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 2px;

    &:visited {
      color: #000;
    }
  }
}

.status_link {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
  text-align: center;
}

.summary-stat {
  display: inline-block;
  border: 1px solid #4656b8;
  color: #4656b8;
  padding: 10px;
  border-radius: 25px;
  margin: 0 40px;
}

.search-field {
  width: 250px;
  margin: 10px !important;
}